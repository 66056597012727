import 'prop-types';
import withConformsTo from '@dbh/with-conforms-to-for-production-www';
import { DAEMON, useBrowserOnlyInjectSaga } from '@dbh/redux-extra';
import { call, put, takeEvery } from 'redux-saga/effects';
import { createDocumentVisibilityChangeWatcher } from '@dbh/dom';
import '@dbh/throw-in-server-side-rendering';
import { requestQueueClearRequested } from '@dbh/request-queue-redux';
import { handleAnalyticsEventSent } from '@dbh/analytics-redux';
import { Map } from 'immutable';
import _noop from 'lodash/noop';

const REDUX_AND_SAGA_KEY="sagas-document-visibility";const DOCUMENT_VISIBILITY_CHANGED="@dbh/redux-document-visibility/DOCUMENT_VISIBILITY_CHANGED";const DOCUMENT_VISIBILITY_NOT_SUPPORTED="@dbh/redux-document-visibility/DOCUMENT_VISIBILITY_NOT_SUPPORTED";

/**
 * Triggered on document visibility change.
 * @param {Object} values To pass to: `createDocumentVisibilityChangeWatcher`.
 * @return {Object} .
 */const documentVisibiilityChanged=withConformsTo("documentVisibiilityChanged",[],a=>({type:DOCUMENT_VISIBILITY_CHANGED,payload:{values:a}}));/**
 * Triggered if the "document visibility" is not supported in the current browser.
 * @return {Object} .
 */const documentVisibilityNotSupported=()=>({type:DOCUMENT_VISIBILITY_NOT_SUPPORTED});

const trackingDocumentVisibilityChanged=withConformsTo("trackingDocumentVisibilityChanged",[],a=>Map({eventPastTense:"DocumentVisibilityChanged",eventCategory:"DOM",eventLabel:a,nonInteraction:!0}));const trackingDocumentVisible=withConformsTo("trackingDocumentVisible",[],a=>Map({eventPastTense:"DocumentVisible",eventCategory:"DOM",eventLabel:a,nonInteraction:!0}));const trackingDocumentHidden=withConformsTo("trackingDocumentHidden",[],a=>Map({eventPastTense:"DocumentHidden",eventCategory:"DOM",eventLabel:a,nonInteraction:!0}));

function*handleVisibilityChanged(a){const{payload:{values:b}}=a,{hidden:c,sentOnDomVisibilityEvent:d}=b,e=JSON.stringify(b);switch("true"!==process.env.TRACKING_GA_DOCUMENT_VISIIBLITY_CHANGE_EVENT_DISABLED&&(yield call(handleAnalyticsEventSent,trackingDocumentVisibilityChanged(e))),d){case"visibilitychange":{c?(yield put(requestQueueClearRequested()),yield call(handleAnalyticsEventSent,trackingDocumentHidden(e))):yield call(handleAnalyticsEventSent,trackingDocumentVisible(e));break}}}

function*reduxDocumentVisibilityRootSaga(a){let{limitedUseDispatch:b}=a;const c=createDocumentVisibilityChangeWatcher(a=>b(documentVisibiilityChanged(a)),!0);c||b(documentVisibilityNotSupported()),yield takeEvery(DOCUMENT_VISIBILITY_CHANGED,handleVisibilityChanged);}const documentVisibilitySagasConfiguration={key:REDUX_AND_SAGA_KEY,mode:DAEMON};

const injectSagaOptions={...documentVisibilitySagasConfiguration,saga:reduxDocumentVisibilityRootSaga};const useBrowserOnlyDocumentVisibilitySaga=()=>useBrowserOnlyInjectSaga(injectSagaOptions);

const visibilityChangeEventPropType=_noop;

export { DOCUMENT_VISIBILITY_CHANGED, DOCUMENT_VISIBILITY_NOT_SUPPORTED, REDUX_AND_SAGA_KEY, documentVisibiilityChanged, documentVisibilityNotSupported, useBrowserOnlyDocumentVisibilitySaga, visibilityChangeEventPropType };
